<template>
    <!-- 视频详情路由 -->
    <div class="video_detail_box">
        <div class="container">
           <!-- 标题 -->
            <div class="video_detail_title">{{info.title}}</div>
            <!-- 小标题 -->
            <div class="video_detail_subtitle">
                <!-- 发布时间 -->
                <img :src="require('@/assets/images/video/@1x/icon_gray_time.png')" alt="">
                <span class="video_detail_subtitle_time">发布于：{{info.create_time}}</span>
                <!-- 热度 -->
                <img :src="require('@/assets/images/video/@1x/icon_hot_1.png')" alt="">
                <span>{{info.hot}}</span>
            </div>
            <!-- 视频插件 -->
            <video-play></video-play>
        </div>
    </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'

export default {
    computed: mapState({
        info: state => state.goodsDetail.info,
    }),
    components: {
        videoPlay: () => import("@/components/videoPlay")
    },
    methods: {
        ...mapMutations([
            "clearInfo",
        ]),
        ...mapActions([
            "getVideoDetail",
        ]),
    },
    beforeRouteEnter (to, from, next) {
        // ...
        next(vm => {
            vm.getVideoDetail(to.query.id);
        });
    },
    beforeRouteLeave (to, from, next) {
        this.clearInfo();
        next();
    },
}
</script>

<style scoped>
/* 视频详情盒子 */
.video_detail_box{
    padding: 50px 0;
}
/* 视频详情标题 */
.video_detail_title{
    font-size: 25px;
    font-weight: bold;
}
/* 小标题 */
.video_detail_subtitle{
    margin: 20px 0;
}
/* 小图标 */
.video_detail_subtitle>img{
    margin-right: 5px;
}
/* 发布时间 */
.video_detail_subtitle_time{
    margin-right: 15px;
}
</style>
